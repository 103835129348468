<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-default-footer
        class="table-border">
        <template #[`item.orderNo`]="{ item }">
          <button
            type="button"
            class="order-button"
            @click="changeRoute(item.orderNo)">
            {{ item.orderNo }}
          </button>
        </template>
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | dateFullFormat() }}
        </template>
        <template #[`item.startDate`]="{ item }">
          {{ item.package.startDate | dateFullFormat() }}
        </template>
        <template #[`item.followers`]="{ item }">
          {{ `${item.followers.length + 1} ท่าน` }}
        </template>
        <template #[`item.channel`]="{ item }">
          {{ getChannel(item.channel) }}
        </template>
        <template #[`item.state`]="{ item }">
          <div
            class="px-4 py-3 rounded-pill my-2"
            :style="{
              width: 'fit-content',
              color: getState(item.state).color,
              backgroundColor: getState(item.state).bgColor,
              userSelect: 'none'
            }">
            {{ getState(item.state).text }}
          </div>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-end">
        <v-pagination
          v-model="query.page"
          :length="totalPages"
          :total-visible="7"
          circle
          color="primary" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import OrdersProvider from '@/resources/orders.provider'

const OrdersService = new OrdersProvider()

export default {
  props: {
    month: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'รหัสออเดอร์',
        value: 'orderNo',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'วันที่ทำรายการ',
        value: 'createdAt',
        class: 'table-header',
        align: 'center',
        sortable: true
      },
      {
        text: 'รหัสแพ็กเกจทัวร์',
        value: 'tour.id',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'วันที่เดินทาง',
        value: 'startDate',
        class: 'table-header',
        sortable: true
      },
      {
        text: 'จำนวนผู้เดินทาง',
        value: 'followers',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'ช่องทางการจอง',
        value: 'channel',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'สถานะ',
        value: 'state',
        class: 'table-header',
        sortable: false,
        width: '180'
      }
    ],
    states: [
      {
        text: 'รอตรวจสอบเอกสาร',
        value: 'inspecting',
        bgColor: '#DED6C8',
        color: '#000000'
      },
      {
        text: 'รอยืนยันการจอง',
        value: 'pending',
        bgColor: '#026EAA1A',
        color: '#000000'
      },
      {
        text: 'รอชำระเงิน',
        value: 'confirmed',
        bgColor: '#FFDF7D',
        color: '#000000'
      },
      {
        text: 'ชำระเงินเสร็จสิ้น',
        value: 'paid',
        bgColor: '#07BAC6',
        color: '#FFFFFF'
      },
      {
        text: 'ยกเลิก',
        value: 'canceled',
        bgColor: '#EB5757',
        color: '#FFFFFF'
      }
    ],
    channels: [
      {
        text: 'เว็บไซต์',
        value: 'website'
      },
      {
        text: 'พนักงาน',
        value: 'manual'
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: '',
      state: 'all'
    },
    totalPages: 1
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    },
    'month': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    changeRoute (id) {
      this.$router.push({
        name: 'OrderEdit',
        params: { id }
      })
    },
    getState (state) {
      const found = this.states.find((s) => s.value === state)
      return found || {
        text: state,
        value: state,
        bgColor: '#026EAA1A',
        color: '#000000'
      }
    },
    getChannel (channel) {
      const found = this.channels.find((s) => s.value === channel)

      return found?.text || channel
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await OrdersService.getItems({
          ...this.query,
          month: this.month,
          memberId: this.$route.params.id
        })

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.order-button {
  font-weight: bold;
  cursor: pointer;
}
</style>
