<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-default-footer
        class="table-border">
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | dateFullFormat() }}
        </template>
        <template #[`item.orderNet`]="{ item }">
          {{ item.orderNet | showNumberFormat() }}
        </template>
        <template #[`item.points`]="{ item }">
          <span v-if="item.type === 'add'">
            +{{ item.points | showNumberFormat() }}
          </span>
          <span v-else>
            -{{ item.points | showNumberFormat() }}
          </span>
        </template>
        <template #[`item.total`]="{ item }">
          {{ item.total | showNumberFormat() }}
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-end">
        <v-pagination
          v-model="query.page"
          :length="totalPages"
          :total-visible="7"
          circle
          color="primary" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import MembersProvider from '@/resources/members.provider'

const MembersService = new MembersProvider()

export default {
  props: {
    month: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'วันที่ทำรายการ',
        value: 'createdAt',
        class: 'table-header',
        align: 'center',
        sortable: true
      },
      {
        text: 'รหัสการจอง',
        value: 'orderNo',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'ยอดการจอง',
        value: 'orderNet',
        class: 'table-header',
        align: 'center',
        sortable: false
      },
      {
        text: 'พอยท์',
        value: 'points',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'คงเหลือ',
        value: 'total',
        class: 'table-header',
        align: 'center',
        sortable: false
      }
    ],
    items: [],
    query: {
      page: 1,
      limit: 10,
      search: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    },
    'month': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await MembersService.getPointHistories({
          ...this.query,
          month: this.month,
          memberId: this.$route.params.id
        })

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
